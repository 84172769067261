(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/widget-area/assets/javascripts/views.js') >= 0) return;  svs.modules.push('/components/core/widget-area/assets/javascripts/views.js');

function isString(string) {
  return typeof string === 'string';
}

function processCrm(crm) {
  var datas = '';
  const crmKeys = crm && Object.keys(crm);

  if (crmKeys) {
    crmKeys.forEach(function(key) {
      datas += 'data-crm-' + key + '="' + crm[key] + '" ';
    });
  }

  return datas;
}

function widgetView(crm, cmsid, body) {
  if (!crm && !cmsid && !body) {
    return '<div class="widget"></div>';
  }

  const crms = processCrm(crm);
  const id = isString(cmsid) ? 'data-cmsid="' + cmsid + '"' : '';
  const bodyString = isString(body) ? body : '';

  return '' +
    '<div class="widget" ' + crms + id + '>' +
    bodyString +
    '</div>';
}

function widgetAreaView(hashClass, hashName, body) {
  if (typeof body === 'undefined') {
    return '';
  }

  const classNames = isString(hashClass) ?
    'class="widget-area ' + hashClass + '" ' :
    'class="widget-area" ';

  const id = isString(hashName) ?
    'id="' + hashName + '"' :
    '';

  body = isString(body) ? body : '';

  return '' +
    '<div ' + classNames + id + '>' +
    body +
    '</div>';
}

const isServerside = typeof exports === 'object';
const views = { widgetView, widgetAreaView };

if (isServerside) {
  module.exports = views;
} else {
  svs.core.widget_area.views = views;
}



 })(window);