(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/widget-area/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/core/widget-area/assets/javascripts/prescript.js');

'use strict';

svs.widget = svs.widget || {};
svs.core = svs.core || {};
svs.core.widget_area = svs.core.widget_area || {};



 })(window);