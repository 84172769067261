(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/widget-area/assets/javascripts/log.js') >= 0) return;  svs.modules.push('/components/core/widget-area/assets/javascripts/log.js');

'use strict';

const isServerside = typeof exports === 'object';
const tag = 'trinidad:widget_area';

function getServersideLog() {
  const trinidad = require(require('path')
    .join(__dirname, '..', '..', '..', '..', '..', 'core')).core;
  return trinidad.logger(tag);
}

function getClientsideLog() {
  return new svs.core.log.Logger(tag);
}

function getLog() {
  return isServerside ?
    getServersideLog() :
    getClientsideLog();
}

if (isServerside) {
  module.exports = getLog();
} else {
  svs.core.widget_area.log = getLog();
}



 })(window);