(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/widget-area/assets/javascripts/handlebars-helper.js') >= 0) return;  svs.modules.push('/components/core/widget-area/assets/javascripts/handlebars-helper.js');

'use strict';

const isServerside = typeof exports === 'object';

const views = isServerside ?
  require(require('path').join(__dirname, 'views.js')) :
  svs.core.widget_area.views;

const log = isServerside ?
  require(require('path').join(__dirname, 'log.js')) :
  svs.core.widget_area.log;

function getWidgetViews(hashName, widgets, context) {
  var widgetViews = '';

  for (var i = 0; i < widgets.length; i++) {
    const currentWidget = widgets[i];

    if (!isServerside && !Handlebars.partials[currentWidget.partial]) {
      widgetViews += views.widgetView();
      continue;
    }

    context.currentWidget = currentWidget.data;
    context.currentWidget.index = i;

    try {
      var body = '';

      if (isServerside) {
        body = typeof currentWidget.template === 'function' ?
          currentWidget.template(context) :
          '';
      } else {
        body = currentWidget.partial ?
          Handlebars.partials[currentWidget.partial](context) :
          '';
      }

      const crm = context.currentWidget.crm;
      const cmsid = context.currentWidget.cms ? context.currentWidget.cms.id : null;

      widgetViews += views.widgetView(crm, cmsid, body);
    } catch (err) {
      widgetViews += views.widgetView();

      if (isServerside) {
        log.error('' +
          'WidgetArea helper failed to create widget: ' +
          hashName +
          ', with error:' +
          err.message);
      }
    }
    delete context.currentWidget;
  }
  return widgetViews;
}

function widgetAttach(hashName, hasPartials) {
  return function() {
    const widgets = svs.widgets.data[hashName];
    const widgetContainer = $('#' + hashName);

    if (widgets && widgets.length) {
      if (hasPartials) {
        svs.core.widget_area.init(hashName);
      } else {
        const widgetViews = getWidgetViews(hashName, widgets, svs.core.data);
        widgetContainer.html(widgetViews || '');
      }
      widgetContainer.trigger('widgetAttached');
    }
  };
}

function getWidgetAreaViewServerside(hashClass, hashName, widgets, context) {
  return views.widgetAreaView(hashClass, hashName, getWidgetViews(hashName, widgets, context));
}

function getWidgetAreaViewClientside(hashClass, hashName, widgets, context) {
  var hasPartials = false;

  for (var i = 0; i < widgets.length; i++) {
    if (Handlebars.partials[widgets[i].partial]) {
      hasPartials = true;
      break;
    }
  }

  const attacher = widgetAttach(hashName, hasPartials);

  if (hasPartials) {
    setTimeout(attacher, 0);
  } else {
    $(attacher);
  }

  return views.widgetAreaView(
    hashClass,
    hashName,
    hasPartials ? getWidgetViews(hashName, widgets, context) : null
  );
}

function registerHelper(hbs) {
  hbs.registerHelper('widgetArea', function(options) {
    var isServerside = typeof exports === 'object';
    if (!options.hash || !options.hash.name) {
      throw new Error('Parameter "name" not supplied for widgetArea helper');
    }

    const data = options.data;
    const hash = options.hash;

    var widgetAreas = {};

    if (isServerside) {
      if (data && data.root && data.root.content) {
        widgetAreas = data.root.content;
      } else {
        widgetAreas = this.content;
      }
    } else {
      widgetAreas = svs.widgets.data;
    }

    const widgets = widgetAreas && widgetAreas[hash.name];

    if (!widgets || !widgets.length) {
      return '';
    }

    const widgetAreaView = isServerside ?
      getWidgetAreaViewServerside(hash.class, hash.name, widgets, data.root) :
      getWidgetAreaViewClientside(hash.class, hash.name, widgets, svs.core.data);

    return new hbs.SafeString(widgetAreaView);
  });
}

if (isServerside) {
  module.exports = registerHelper;
} else {
  registerHelper(Handlebars);
}



 })(window);