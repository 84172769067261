(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/widget-area/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/core/widget-area/assets/javascripts/init.js');

'use strict';

const log = svs.core.widget_area.log;

function getEl(hashName, index) {
  return $($(`#${hashName} .widget`).get(index)).children();
}

function getWidgetInitData(hashName, index, widget, model) {
  const $el = $($(`#${hashName} .widget`).get(index)).children();

  if (!widget.templates) {
    return model;
  } else if ($el.length) {
    return { $el, model };
  }

  return null;
}

function initNextWidget(hashName, widgetData, index) {
  const widgetName = widgetData ? widgetData.widget : null;
  const widget = widgetName && svs.widget[widgetName];

  if (!widget || typeof widget.init !== 'function') {
    return;
  }

  const initData = getWidgetInitData(hashName, index, widget, widgetData.data);

  try {
    if (initData) {
      widget.init(initData);
    } else {
      $('#' + hashName).on('widgetAttached', function() {
        if ($('#' + hashName).length) {
          widget.init({
            $el: getEl(hashName, index),
            model: widgetData.data
          });
        }
      });
    }
  }
  catch (error) {
    var message = ' with unknown error';

    if (typeof error === 'object' && error.message) {
      message = ' with error: ' + error.message;
    }

    log.warn('failed to init: ' + hashName + message);
  }
}

function init(hashName) {
  const widgetAreas = svs.widgets ? svs.widgets.data : null;

  if (!widgetAreas) {
    log.error('svs.widgets.data is falsy');
    return;
  }

  const widgetAreaHashNames = widgetAreas[hashName] ? [hashName] : Object.keys(widgetAreas);

  for (var i = 0; i < widgetAreaHashNames.length; i++) {
    const thisHashName = widgetAreaHashNames[i];
    const widgets = widgetAreas[thisHashName];

    if (widgets && widgets.length) {
      for (var j = 0; j < widgets.length; j++) {
        initNextWidget(thisHashName, widgets[j], j);
      }
    }
  }
}

$(function() {
  init();
});

svs.core.widget_area.init = init;



 })(window);